.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;

  .wrapper {
    width: 100%;
    margin-top: 10vh;
    padding: 2rem;
    overflow: scroll;
    height: auto;
  }
}

@import "./styles/home.scss";
@import "./styles/navbar.scss";
@import "./styles/newUser.scss";
@import "./styles/login.scss";
@import "./styles/updateUser.scss";
@import "./styles/deleteUser.scss";
