.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  margin-top: 5rem;
  width: 100%;
  .tab {
    width: 20%;
    height: 15rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    background-color: #1442be;
    font-size: 2em;
    font-weight: lighter;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    margin: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
