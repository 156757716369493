.update-gc-container {
    min-height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    label {
        margin-top: 2rem;
        font-size: .7rem;
    }
  
    .submit-btn {
      width: 30%;
      margin-top: 2rem;
    }
  }
    