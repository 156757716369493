.navbar {
  width: 100%;
  height: 10vh;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  padding: 0 2rem;
  z-index: 10;

  .nav-button {
    width: 120px;
    height: 40px;
    border-radius: 31px;
    outline: none;
    background-color: #1442be;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 1rem;

    &:hover {
      transform: scale(1.1);
    }

    &:disabled {
      transform: none;
      background-color: grey;
    }
  }

  img {
    height: 20px;
  }
}
