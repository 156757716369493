.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  .invoid-logo {
    align-self: flex-end;
    height: 28px;
    width: 140px;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .form-container {
    justify-content: center;
    align-items: center;
    height: 30%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    margin: 4rem 1rem;
  }
}
