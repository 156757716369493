.update-user-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  .user-data {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 16px 0px 16px;
    justify-content: space-around;
    .limit-input {
      width: 20%;
      margin: 1rem;
      .MuiFormLabel-root {
        z-index: 0;
      }
    }
  }

  .submit-btn {
    width: 30%;
    margin: auto;
  }

  // .govt-check {
  //   .MuiDialog-paperScrollPaper{
  //     display: flex;
  //     flex-direction: row;
  //     padding: 1rem;
  //     flex-wrap: wrap;
  //   }
  // }
}
  