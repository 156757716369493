.create-user-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.75);
  padding: 2rem 4rem;
  width: 70%;

  .name {
    margin: 2rem 0;
  }

  .user-form {
    display: flex;
    flex-direction: column;
  }

  .submit-btn {
    margin: 30px 0;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    font-weight: bolder;
  }
}
