* {
  font-family: 'Lato', sans-serif !important;
}
body {
  margin: 0px;
}
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    align-self: center;
    animation: spin 2s linear infinite;
    margin: auto;
    margin-top: 50px;
  }

  .transLoader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    align-self: center;
    animation: spin 2s linear infinite;
    
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .myspan {
    padding: 10px;
    border: gray solid 1px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-right: 16px;
  }

  .leftMargin {
    margin-left: 16px;
  }

  /* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  color: #242526;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 14px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: rgba(21,75,204, 10);
  color: white;
}

/* Create an active/current tablink class */
/* .tab button.active {
  background-color: #ccc;
} */

#tablinks {
  background-color: rgb(21,75,204);
  color: white;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

div.horizontalgap {
  float: left;
  overflow: hidden;
  height: 1px;
  width: 10px;
}


.downloadButton {
  padding: 7px 10px;
  height: 32px;
  border: none;
  color: white;
  text-align: center;
  margin-left: 16px;
  background-color: #4CAF50;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
  
}

.downloadButton:disabled{
  padding: 7px 10px;
  height: 32px;
  border: none;
  color: white;
  text-align: center;
  margin-left: 16px;
  background-color: #4CAF5088;
  cursor: not-allowed;
  text-decoration: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 16px;

  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 105%; 

}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.error {
  text-align: center;
  background-color: gray;
  padding: 8px;
  width: 50%;
  margin:auto;
  margin-top: 8px;
  color: white;
}

.header {
  background-image: linear-gradient(mediumblue,darkblue);
  padding-top: 16px;
  padding-bottom: 16px;
}

.header-text {
  color: white;
  margin-left: 24px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
  font-weight: 700;
}
.header-image {
  height: 40px;
  margin-left: 24px;
}

.myFlexDiv {
  display: flex;

}

.gcDocContainer {
  display: flex;
  flex-wrap: wrap;
}


.gcSingleDoc {
  width: 100px;
  padding: 8px;
  margin-top: 16px;
  margin-right: 16px;
  text-align: center;
  /* border: gray solid 1px; */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  min-height: 80px;
  border-radius: 5px;
}

.gcSingleDoc:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.myFloatLeft {
  margin-top: 4px;
}

.myFlexContainer {
  display: flex;
  flex-wrap: wrap;
}

.myLi {
  list-style-type: none;
}

table, th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid black;
  border-collapse: collapse;

}

.dateLabel {
  color: #242526;
  padding: 16px;
  text-align: center;
  font-size: 14px;
}


